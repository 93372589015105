import {
  Cabin_400Regular, Cabin_500Medium, Cabin_600SemiBold, Cabin_700Bold, Cabin_400Regular_Italic,
} from '@expo-google-fonts/cabin';
import { Inconsolata_400Regular } from '@expo-google-fonts/inconsolata';
import { PlayfairDisplay_900Black, useFonts } from '@expo-google-fonts/playfair-display';
import { TextStyle } from 'react-native';

import Icons from './IconsRegular';
import Filled from './IconsFilled';

export const initFonts = () => useFonts({
  PlayfairDisplay_900Black,
  Cabin_400Regular,
  Cabin_400Regular_Italic,
  Cabin_500Medium,
  Cabin_600SemiBold,
  Cabin_700Bold,
  Inconsolata_400Regular,
})[0];

const Color = {
  Primary: '#3E6FF6',
  PrimaryD: '#3259C5',
  Secondary: '#FFC000',
  Tertiary: '#FF5C5C',
  Quartenary: '#DFB187',
  White: '#FFFFFF',
  Gray4: '#BDBDBD',
  Gray100: '#F6F6F6',
  Gray200: '#EEEEEE',
  Gray200Br: '#EBEBEB',
  Gray300: '#C2C2C2',
  Gray400: '#999999',
  Gray600: '#333333',
  Gray800: '#161616',
  Green: '#00A456',
  Black: '#000000',
  Transparent: 'transparent',
  Avatar: ['#C8AFAA', '#FFDC70', '#FF6278', '#94EDF7', '#A89EC3', '#FF8166', '#97C8B6'],
};

const Shadow = {
  ButtonHovered: {
    elevation: 4,
    shadowColor: Color.Black,
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 8,
  },
  ButtonPressed: {
    elevation: 2,
    shadowColor: Color.Black,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowOpacity: 0.3,
    shadowRadius: 2,
  },
};

const Text = {
  Heading1: {
    fontFamily: 'PlayfairDisplay_900Black',
    fontSize: 32,
  } as TextStyle,
  Heading2: {
    fontFamily: 'Cabin_700Bold',
    fontSize: 24,
  } as TextStyle,
  Heading3: {
    fontFamily: 'Cabin_600SemiBold',
    fontSize: 19,
  } as TextStyle,
  Heading4: {
    fontFamily: 'Cabin_500Medium',
    fontSize: 17,
  } as TextStyle,
  Caption: {
    fontFamily: 'Cabin_400Regular',
    fontSize: 17,
  } as TextStyle,
  Button: {
    fontFamily: 'Cabin_700Bold',
    fontSize: 15,
  } as TextStyle,
  Normal: {
    fontFamily: 'Cabin_400Regular',
    fontSize: 15,
    lineHeight: 22,
  } as TextStyle,
  Small: {
    fontFamily: 'Cabin_400Regular',
    fontSize: 13,
  } as TextStyle,
  SmallBold: {
    fontFamily: 'Cabin_700Bold',
    fontSize: 13,
  } as TextStyle,
  Code: {
    fontFamily: 'Inconsolata_400Regular',
    fontSize: 13,
  } as TextStyle,
  Tiny: {
    fontFamily: 'Cabin_400Regular',
    fontSize: 11,
  } as TextStyle,
  NormalItalic: {
    fontFamily: 'Cabin_400Regular_Italic',
    fontSize: 15,
    lineHeight: 22,
  } as TextStyle,
};

const hashString = (str: string) => {
  const len = str.length;
  if (len === 0) return 0;
  let hash = 0;
  for (let i = 0; i < len; i += 1) {
    const char = str.charCodeAt(i);
    /* eslint-disable no-bitwise */
    hash = ((hash << 5) - hash) + char;
    hash |= 0; // Convert to 32bit integer
    /* eslint-enable no-bitwise */
  }
  return Math.abs(hash);
};

const getAvatarColor = (code: string) => Color.Avatar[hashString(code) % Color.Avatar.length];

export const getMenuItemColor = (
  selected = false,
  hovered = false,
  focused = false,
  pressed = false,
) => {
  if (selected) return Color.Primary;
  if (hovered || focused || pressed) return Color.Black;
  return Color.Gray400;
};

export default {
  Text,
  Color,
  Shadow,
  Icon: {
    ...Icons,
    Filled,
  },
  getAvatarColor,
  getMenuItemColor,
};
