import * as Linking from 'expo-linking';
import moment from 'moment';
import { NativeScrollEvent, Platform } from 'react-native';
import _ from 'lodash';

import { Call, Ledger, Module, RecAvailability } from '../types';
import { AvailableTimeSlots } from '../types/Availabilities';
import { IconName } from '../style/IconsRegular';
import I18n from '../i18n';

/**
 * Open an external URL.
 *
 * @param url destination URL
 * @param newTab whether to open a new tab (only relevant on web)
 */
export const openUrl = (url: string, newTab = false) => {
  if (Platform.OS === 'web' && newTab) {
    window.open(url, '_blank');
  } else {
    Linking.openURL(url).catch((err) => {
      // Call Sentry
      // eslint-disable-next-line no-console
      console.log(err);
    });
  }
};

export const SUCCESS = 'success';
export const MIN_INNER_WIDTH = 1100;
export const MIN_SQUEEZE_WIDTH = 320;
export const EMAIL_RX = /\S+@\S+\.\S{2,}/;
export const PASS_RX = /^[ -~]{8,72}$/;
// In api-server, the regex for name is: /^[\p{Letter}\-. ']+$/u;
// But \p{Letter} does not support here,
export const NAME_RX = /^(?!\s*$)[a-zA-ZÀ-ÿ\u4E00-\u9FCC\-. ']+$/u;

export const ALLOWED_TIME_SLOTS = [
  '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30',
  '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00',
  '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
  '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00',
];

export const convertMsToHM = (ms: number) => {
  if (ms > 86400000) return '24:00'; // a day has max 24 hours.
  const tempTime = moment.duration(ms);
  let hh = tempTime.hours().toString();
  let mm = tempTime.minutes().toString();

  /* Moment library returns 0 for both 0 and 86400000
  As we do not have any time slot starting from 0 ms, so we consider return 0 === 24 hr. */
  if (hh === '0') hh = '24';
  if (mm === '0') mm = '00';

  const hhmm = `${hh}:${mm}`;
  return hhmm;
};

export const convertHmToMs = (timeString: string) => {
  const hh = Number(timeString.split(':')[0]);
  const mm = Number(timeString.split(':')[1]);
  const ms = hh * 60 * 60 * 1000 + mm * 60 * 1000;
  return ms;
};

export const isCallMoveable = (call: Call) => call.status === 'confirmed' || call.status === 'failed';

export const isCallResettable = (call: Call, module?: Module) => isCallMoveable(call) && module?.callType === 'arranged';

export const isCallObsoletable = (call: Call, module?: Module) => call.status === 'failed' || (call.status === 'confirmed' && module?.callType === 'metered');

export const showObsoleteWarning = (mod?: Module) => mod?.callType !== 'metered';

export const filterPastCalls = (calls?: Call[]) => _.filter(calls, (call) => {
  if (!calls) return false;
  if (call.status === 'pending') return false;
  const isTimePassed = moment().isAfter(call.start);
  return isTimePassed;
});

export const filterLast90DaysCalls = (calls?: Call[]) => _.filter(calls, (call) => {
  if (!calls) return false;
  const date = moment().add(-90, 'days');
  return call.start && moment(call.start).isAfter(date);
});

export const createArrayFromApiData = (dataFromAPI?: RecAvailability[]) => {
  /* dataFromAPI should be a list of objects or empty list
  from recAvailabilities API */
  const weeklyAvails: AvailableTimeSlots = [[], [], [], [], [], [], []];
  if (!dataFromAPI || dataFromAPI.length === 0) return weeklyAvails;

  dataFromAPI.forEach((element) => {
    const timeSlot = [convertMsToHM(element.start), convertMsToHM(element.end)];
    weeklyAvails[element.isoWeekday - 1].push(timeSlot);
  });

  return weeklyAvails;
};

export const createDataForApiFromArray = (
  availableTimeSlots: AvailableTimeSlots,
  skills: string[],
) => {
  const availTimeSlotsForAPI: RecAvailability[] = [];
  for (let weekDay = 0; weekDay < 7; weekDay += 1) {
    availableTimeSlots[weekDay].forEach((timeSlot) => {
      const eachTimeSlotDetails: RecAvailability = {
        isoWeekday: weekDay + 1,
        start: convertHmToMs(timeSlot[0]),
        end: convertHmToMs(timeSlot[1]),
        skills,
      };
      availTimeSlotsForAPI.push(eachTimeSlotDetails);
    });
  }
  return availTimeSlotsForAPI;
};

export const capFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const getDefaultCallStart = (minStartHour: number, call: Call | undefined = undefined) => {
  const defaultTime = moment().add(2, 'hours').set({
    minute: 0,
  });

  if (Number(defaultTime.local().format('H')) < minStartHour) {
    defaultTime.set({
      hour: 10,
      minute: 0,
    });
  }

  if (!call) return defaultTime.local();

  if (moment(call.start).isBefore()) return defaultTime.local();
  return moment(call.start);
};

export const parentRouteName = (routeName: string) => {
  // To show sidebar item name selected when it routes to child page
  switch (routeName) {
    case 'Projects':
    case 'ProjectDetails':
      return 'Projects';
    default:
      return routeName;
  }
};

export const getCallEventIcon = (event: string): IconName => {
  switch (event) {
    case 'Move':
      return 'ArrowSquareRight';
    case 'Reset':
      return 'Warning';
    case 'Fail':
      return 'XCircle';
    case 'Obsolete':
      return 'Warning';
    case 'Schedule':
      return 'Phone';
    default:
      return 'Info';
  }
};

export const getUrlParamValue = (param: string) => {
  const urlQuery = window.location.search;
  const params = new URLSearchParams(urlQuery);
  return params.get(param);
};

export const isCloseToBottom = (e: NativeScrollEvent, delta = 20) => (
  e.layoutMeasurement.height + e.contentOffset.y >= e.contentSize.height - delta);

export const createMeteredCallDurArray = (credit: number, minDur = 15, interval = 15) => {
  const arr = _.range(minDur, credit, interval);
  const lastVal = arr[arr.length - 1];
  if (lastVal !== credit) {
    if (lastVal > credit - minDur) arr[arr.length - 1] = credit;
    else arr.push(credit);
  }

  // just to take maximum 4 time duration i.e. 15/30/45/60
  return arr.slice(0, 4);
};

export const getModuleCredits = (userLedger?: Ledger, modKey?: string) => (
  _.find(userLedger?.moduleCredit, { key: modKey }));

export const showCallStatusIcon = (call: Call) => call.status === 'failed';

export const getCallCancelModalText = (isObsoleteSection: boolean) => {
  if (isObsoleteSection) {
    return {
      title: I18n.t('ui.calls.discardCall'),
      details: I18n.t('ui.calls.discardCallDetails'),
      warning: I18n.t('ui.calls.discardCallWarning'),
      reason: I18n.t('ui.calls.reason'),
      placeholder: I18n.t('ui.calls.discardReasonPlaceholder'),
      successNotif: I18n.t('ui.calls.discardCallSuccess'),
      buttonTitle: I18n.t('ui.buttons.discard'),
      buttonIconName: 'CalendarX',
    };
  }

  return {
    title: I18n.t('ui.calls.resetCall'),
    warning: I18n.t('ui.calls.resetCallWarning'),
    reason: I18n.t('ui.calls.reason'),
    placeholder: I18n.t('ui.calls.resetReasonPlaceholder'),
    successNotif: I18n.t('ui.calls.resetCallSuccess'),
    buttonTitle: I18n.t('ui.buttons.reset'),
    buttonIconName: 'ArrowLineLeft',
  };
};
